'use client';

import { ReactNode } from "react";

export interface BadgeProps {
  /**
   * The tooltip text
   */
  tootltip?: string;

  /**
   * Is the badge absolute
   */
  isFloating?: boolean;

  /**
   * The children elements
   */
  children?: ReactNode;
}

/**
 * Generate a custom themes QR code
 */
export default function Badge({
  tootltip,
  isFloating,
  children,
  ...props
}: BadgeProps) {
  return (
    <div
      className={[
        'w-9 h-9 transition-all rounded-full flex items-center content-center justify-items-center justify-center border-2 border-white bg-white hover:bg-green-900 text-moss hover:text-white group hover:scale-110',
        isFloating
          ? 'absolute bottom-0 right-0 -mb-20 -mr-20 shadow-md'
          : 'relative',
      ].join(' ')}
      {...props}
    >
      {children}
      <div className='absolute bottom-full left-1/2 transform -translate-x-1/2 mb-2 hidden group-hover:block bg-black text-white text-tooltip text-center rounded py-2 px-4 max-w-max w-32 sm:w-64 pointer-events-none'>
        {tootltip}
        <div className='absolute top-full left-1/2 transform -translate-x-1/2 border-t-[6px] border-t-black border-x-transparent border-x-[6px] border-b-0'></div>
      </div>
    </div>
  );
}
