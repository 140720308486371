'use client';

import { ChangeEvent, useState } from 'react';

/**
 * Generate a custom onboarding form in a modal
 */
export default function Onboarding() {
  const FORM_ID = process.env.NEXT_PUBLIC_FORM_ID_ONBOARDING;
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);

  const handleCheckbox = (e: ChangeEvent<HTMLInputElement>) => {
    setIsError(false);
    setIsCheckboxChecked(e.target.checked);
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!isCheckboxChecked) {
      setIsError(true);
      return;
    }

    setIsSubmitting(true);

    const form = e.currentTarget;
    const formData = new FormData(form);

    fetch(`https://formbold.com/s/${FORM_ID}`, {
      method: 'POST',
      body: formData,
    })
      .then(() => {
        setIsSubmitting(false);
        setIsSubmitted(true);
        form.reset();
        setIsCheckboxChecked(false);
      })
      .catch((error) => {
        setIsSubmitting(false);
        console.error('Form submission failed:', error);
        alert('Something went wrong. Please try again.');
      });
  };

  const handleCloseModal = () => setIsModalOpen(false);

  return (
    <>
      <section className='relative overflow-hidden'>
        <div className='w-full mx-auto'>
          <div className='bg-slide-static py-12 md:py-20'>
            <div className='relative mx-auto max-w-3xl text-center z-10'>
              <h2
                className='animate-[gradient_6s_linear_infinite] bg-[linear-gradient(to_right,theme(colors.gray.200),theme(colors.indigo.200),theme(colors.gray.50),theme(colors.indigo.300),theme(colors.gray.200))] bg-[length:200%_auto] bg-clip-text pb-8 font-poppins text-3xl font-semibold text-smoke md:text-4xl aos-init aos-animate'
                data-aos='fade-up'
                data-aos-delay={200}
              >
                Interested in earning sustainability badges for your business?
              </h2>
              <div className='mx-auto max-w-xs sm:flex sm:max-w-none sm:justify-center'>
                <div data-aos='fade-up' data-aos-delay={400}>
                  <button
                    onClick={() => setIsModalOpen(true)}
                    className='btn group mb-4 w-full bg-gradient-to-t from-moss-dark to-moss-light bg-[length:100%_100%] bg-[bottom] text-white shadow-[inset_0px_1px_0px_0px_theme(colors.white/.16)] hover:bg-[length:100%_150%] sm:mb-0 sm:w-auto'
                  >
                    <span className='relative inline-flex items-center'>
                      Obtain a badge
                      <span className='ml-1 tracking-normal text-white/50 transition-transform group-hover:translate-x-0.5'>
                        -&gt;
                      </span>
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {isModalOpen && (
        <div className='fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 backdrop-blur-sm'>
          <div
            className='fixed top-0 left-0 w-full h-full z-0'
            onClick={() => setIsModalOpen(false)}
          ></div>
          <div className='relative w-full max-w-md rounded-lg bg-beige p-6 z-10'>
            <button
              className='absolute top-2 right-2 text-gray-500 hover:text-gray-700'
              onClick={handleCloseModal}
              aria-label='Close'
            >
              <svg
                xmlns='http://www.w3.org/2000/svg'
                fill='none'
                viewBox='0 0 24 24'
                strokeWidth={2}
                stroke='currentColor'
                className='h-6 w-6'
              >
                <path
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  d='M6 18L18 6M6 6l12 12'
                />
              </svg>
            </button>
            <h4 className='mb-4 text-2xl font-bold text-black'>
              Obtain a badge
            </h4>
            <p className='mb-4 italic'>
              “A business that prioritises people and the planet leaves a legacy
              of true success”.
            </p>
            <p className='mb-8 font-bold'>
              Start by providing your business details:
            </p>
            <form onSubmit={handleSubmit}>
              <div className='relative mb-4'>
                <input
                  type='text'
                  name='business'
                  placeholder='Business Name'
                  className='w-full rounded-full border border-stroke px-6 py-3 shadow focus:border-primary focus:outline-none'
                  disabled={isSubmitting}
                  required
                />
              </div>
              <div className='relative mb-4'>
                <input
                  type='url'
                  name='website'
                  placeholder='Business Website'
                  className='w-full rounded-full border border-stroke px-6 py-3 shadow focus:border-primary focus:outline-none'
                  disabled={isSubmitting}
                  required
                />
              </div>
              <div className='relative mb-4'>
                <input
                  type='text'
                  name='name'
                  placeholder='Main Contact Name'
                  className='w-full rounded-full border border-stroke px-6 py-3 shadow focus:border-primary focus:outline-none'
                  disabled={isSubmitting}
                  required
                />
              </div>
              <div className='relative mb-4'>
                <input
                  type='tel'
                  name='phone'
                  placeholder='Contact Number'
                  className='w-full rounded-full border border-stroke px-6 py-3 shadow focus:border-primary focus:outline-none'
                  disabled={isSubmitting}
                  required
                />
              </div>
              <div className='relative mb-4'>
                <input
                  type='email'
                  name='email'
                  placeholder='Email address'
                  className='w-full rounded-full border border-stroke px-6 py-3 shadow focus:border-primary focus:outline-none'
                  disabled={isSubmitting}
                  required
                />
              </div>
              <div className='relative mb-4'>
                <div className='mb-4 flex md:mb-0'>
                  <input
                    id='consent-checkbox'
                    type='checkbox'
                    name='consent'
                    value='consented'
                    className='peer sr-only'
                    onChange={handleCheckbox}
                    disabled={isSubmitting}
                  />
                  <span className='group absolute flex h-5 w-5 items-center justify-center rounded border-gray-300 bg-gray-100 text-green-700 peer-checked:bg-primary dark:border-gray-600 dark:bg-gray-700 pointer-events-none'>
                    <svg
                      className='opacity-0 peer-checked:group-[]:opacity-100'
                      width='10'
                      height='8'
                      viewBox='0 0 10 8'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        fillRule='evenodd'
                        clipRule='evenodd'
                        d='M9.70704 0.792787C9.89451 0.980314 9.99983 1.23462 9.99983 1.49979C9.99983 1.76495 9.89451 2.01926 9.70704 2.20679L4.70704 7.20679C4.51951 7.39426 4.26521 7.49957 4.00004 7.49957C3.73488 7.49957 3.48057 7.39426 3.29304 7.20679L0.293041 4.20679C0.110883 4.01818 0.0100885 3.76558 0.0123669 3.50339C0.0146453 3.24119 0.119814 2.99038 0.305222 2.80497C0.490631 2.61956 0.741443 2.51439 1.00364 2.51211C1.26584 2.50983 1.51844 2.61063 1.70704 2.79279L4.00004 5.08579L8.29304 0.792787C8.48057 0.605316 8.73488 0.5 9.00004 0.5C9.26521 0.5 9.51951 0.605316 9.70704 0.792787Z'
                        fill='white'
                      />
                    </svg>
                  </span>
                  <label
                    htmlFor='consent-checkbox'
                    className='flex max-w-[425px] cursor-pointer select-none pl-8'
                  >
                    I agree to the Privacy Policy and Terms of Use.
                  </label>
                </div>
              </div>
              <div className='relative mt-8 text-right'>
                <button
                  type='submit'
                  aria-label='signup to newsletter'
                  className='btn group mb-4 w-full bg-gradient-to-t from-moss-dark to-moss-light bg-[length:100%_100%] bg-[bottom] text-white shadow-[inset_0px_1px_0px_0px_theme(colors.white/.16)] hover:bg-[length:100%_150%] sm:mb-0 sm:w-auto'
                  disabled={isSubmitting}
                >
                  <span className='relative inline-flex items-center'>
                    Submit Form
                    <span className='ml-1 tracking-normal text-white/50 transition-transform group-hover:translate-x-0.5'>
                      -&gt;
                    </span>
                  </span>
                </button>
              </div>
              {isSubmitting && (
                <p className='mt-2 mb-4 text-yellow-500 text-right'>
                  Submitting...
                </p>
              )}
              {isError && (
                <p className='mt-2 mb-4 text-red-600 text-right'>
                  You must agree to the terms.
                </p>
              )}
              {isSubmitted && (
                <>
                  <p className='mt-2 mb-4 text-green-700 text-right'>
                    Thank you,
                  </p>
                  <p className='mb-4 text-green-700'>
                    Within 24 hours, we will complete an initial review of your
                    business, after which you can create your profile and begin
                    assessing your eligibility for badges.
                  </p>
                </>
              )}
            </form>
          </div>
        </div>
      )}
    </>
  );
}
