'use client';

import { useState } from 'react';

export interface WaitingListProps {
  isModal?: boolean;
}

/**
 * Generate a custom waiting list form with optional modal functionality
 */
export default function WaitingList({ isModal = false }: WaitingListProps) {
  const FORM_ID = process.env.NEXT_PUBLIC_FORM_ID_NEWSLETTER;
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsSubmitting(true);

    const form = e.currentTarget;
    const formData = new FormData(form);

    fetch(`https://formbold.com/s/${FORM_ID}`, {
      method: 'POST',
      body: formData,
    })
      .then(() => {
        setIsSubmitting(false);
        setIsSubmitted(true);
        form.reset();
        setTimeout(() => {
          setIsSubmitted(false);
          setIsModalOpen(false);
        }, 2000);
      })
      .catch((error) => {
        setIsSubmitting(false);
        console.error('Form submission failed:', error);
        alert('Something went wrong. Please try again.');
      });
  };

  const handleCloseModal = () => setIsModalOpen(false);

  if (isModal) {
    return (
      <>
        <button
          onClick={() => setIsModalOpen(true)}
          className='btn group w-full bg-gradient-to-t from-green-950 to-green-800 bg-[length:100%_100%] bg-[bottom] text-white shadow-[inset_0px_1px_0px_0px_theme(colors.white/.16)] hover:bg-[length:100%_150%] md:w-auto'
        >
          Join waiting list
          <span className='ml-1 tracking-normal text-white/50 transition-transform group-hover:translate-x-0.5'>
            -&gt;
          </span>
        </button>

        {isModalOpen && (
          <div className='fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 backdrop-blur-sm'>
            <div
              className='fixed top-0 left-0 w-full h-full z-0'
              onClick={() => setIsModalOpen(false)}
            ></div>
            <div className='relative w-full max-w-md rounded-lg bg-beige p-6 z-10'>
              <button
                className='absolute top-2 right-2 text-gray-500 hover:text-gray-700'
                onClick={handleCloseModal}
                aria-label='Close'
              >
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  fill='none'
                  viewBox='0 0 24 24'
                  strokeWidth={2}
                  stroke='currentColor'
                  className='h-6 w-6'
                >
                  <path
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    d='M6 18L18 6M6 6l12 12'
                  />
                </svg>
              </button>
              <h4 className='mb-4 text-2xl font-bold text-black'>
                WSS Waiting List
              </h4>
              <p className='mb-4'>Please provide us with your email address</p>
              <form onSubmit={handleSubmit}>
                <div className='relative'>
                  <input
                    type='email'
                    name='email'
                    placeholder='Email address'
                    className='w-full rounded-full border border-stroke px-6 py-3 shadow focus:border-primary focus:outline-none'
                    disabled={isSubmitting}
                    required
                  />
                  <button
                    type='submit'
                    aria-label='signup to newsletter'
                    className='absolute right-0 p-4'
                    disabled={isSubmitting}
                  >
                    <svg
                      className={`fill-gray-500 ${
                        isSubmitting ? 'opacity-50' : 'hover:fill-primary'
                      }`}
                      width='20'
                      height='20'
                      viewBox='0 0 20 20'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        d='M3.1175 1.17318L18.5025 9.63484C18.5678 9.67081 18.6223 9.72365 18.6602 9.78786C18.6982 9.85206 18.7182 9.92527 18.7182 9.99984C18.7182 10.0744 18.6982 10.1476 18.6602 10.2118C18.6223 10.276 18.5678 10.3289 18.5025 10.3648L3.1175 18.8265C3.05406 18.8614 2.98262 18.8792 2.91023 18.8781C2.83783 18.8769 2.76698 18.857 2.70465 18.8201C2.64232 18.7833 2.59066 18.7308 2.55478 18.6679C2.51889 18.6051 2.50001 18.5339 2.5 18.4615V1.53818C2.50001 1.46577 2.51889 1.39462 2.55478 1.33174C2.59066 1.26885 2.64232 1.2164 2.70465 1.17956C2.76698 1.14272 2.83783 1.12275 2.91023 1.12163C2.98262 1.12051 3.05406 1.13828 3.1175 1.17318ZM4.16667 10.8332V16.3473L15.7083 9.99984L4.16667 3.65234V9.16651H8.33333V10.8332H4.16667Z'
                        fill=''
                      />
                    </svg>
                  </button>
                </div>
                {isSubmitting && (
                  <p className='mt-2 text-yellow-500'>Submitting...</p>
                )}
                {isSubmitted && (
                  <p className='mt-2 text-green-700'>Thank you for joining!</p>
                )}
              </form>
            </div>
          </div>
        )}
      </>
    );
  }

  return (
    <div>
      <h4 className='mb-9 text-itemtitle2 font-medium text-black dark:text-white'>
        Waiting list
      </h4>
      <p className='mb-4 w-[90%]'>Please provide us with your email address</p>
      <form onSubmit={handleSubmit}>
        <div className='relative'>
          <input
            type='email'
            name='email'
            placeholder='Email address'
            className='w-full rounded-full border border-stroke px-6 py-3 shadow focus:border-primary focus:outline-none'
            disabled={isSubmitting}
            required
          />
          <button
            type='submit'
            aria-label='signup to newsletter'
            className='absolute right-0 p-4'
            disabled={isSubmitting}
          >
            <svg
              className={`fill-gray-500 ${
                isSubmitting ? 'opacity-50' : 'hover:fill-primary'
              }`}
              width='20'
              height='20'
              viewBox='0 0 20 20'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M3.1175 1.17318L18.5025 9.63484C18.5678 9.67081 18.6223 9.72365 18.6602 9.78786C18.6982 9.85206 18.7182 9.92527 18.7182 9.99984C18.7182 10.0744 18.6982 10.1476 18.6602 10.2118C18.6223 10.276 18.5678 10.3289 18.5025 10.3648L3.1175 18.8265C3.05406 18.8614 2.98262 18.8792 2.91023 18.8781C2.83783 18.8769 2.76698 18.857 2.70465 18.8201C2.64232 18.7833 2.59066 18.7308 2.55478 18.6679C2.51889 18.6051 2.50001 18.5339 2.5 18.4615V1.53818C2.50001 1.46577 2.51889 1.39462 2.55478 1.33174C2.59066 1.26885 2.64232 1.2164 2.70465 1.17956C2.76698 1.14272 2.83783 1.12275 2.91023 1.12163C2.98262 1.12051 3.05406 1.13828 3.1175 1.17318ZM4.16667 10.8332V16.3473L15.7083 9.99984L4.16667 3.65234V9.16651H8.33333V10.8332H4.16667Z'
                fill=''
              />
            </svg>
          </button>
        </div>
        {isSubmitting && <p className='mt-2 text-yellow-500'>Submitting...</p>}
        {isSubmitted && (
          <p className='mt-2 text-green-700'>Thank you for joining!</p>
        )}
      </form>
    </div>
  );
}
